*,
*::after,
*::before{
    box-sizing: border-box;
}

html {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

a {
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: #10111b;
}

ul, li, h1, h2, h3, h4, h5, h6 {
    list-style: none;
    padding: 0;
    margin: 0;
}