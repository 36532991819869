
.error50x {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #e5e5e5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #10111b;
    padding: 0 15px;
}
.logo {
    &-link {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(../img/fox.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width:375px) {
            background-image: url(../img/fox-small.png);
            }
    }
}
.error50x {
    &-logo {
        margin: 0 auto 20px;
        width: 146px;
        height: 150px;
        @media (max-width:375px) {
            width: 73px;
            height: 75px;
            margin: 15% auto 20px;
            }
        }
    &-50x {
        font-weight: 600;
        font-size: 80px;
        line-height: 109px;
        @media (max-width:375px) {
            font-size: 60px;
            line-height: 82px;
        }
    }
    &-error {
        text-transform: uppercase;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 30px;
        @media (max-width:375px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    &-sorry {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    }
    &-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 90px;
        @media (max-width:540px) {
            flex-direction: column-reverse;
            margin-bottom: 60px;
        }
        @media (max-width:365px) {
            width: 90%;
            margin-bottom: 45px;
        }
    
    }
    &-contact {
        text-align: center;
        & a{
      color: #6D94C4;
        }
        @media (max-width:375px) {
           max-width: 92%;
            }
    }
}
.wrapper-inner {
    width: 242px;
    height: 50px;
    &:nth-of-type(2) {
        margin-bottom: 15px;
    }

    @media (max-width:540px) {
        width: 345px;
    }
    @media (max-width:365px) {
        width: 100%;
    }

}
.wrapper-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    & span {
        display: block;
    }
    &_left {
        border: 1px solid #10111b;
          }       
    &_right{
        background-color: #6D94C4;
        margin-left: 30px;
        @media (max-width:670px) {
            margin-left: 10px;
        }
        @media (max-width:540px) {
            margin-left: 0;               
        }
    }
}