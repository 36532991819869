
.error404 {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #10111b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #eeeeee;
    padding: 0 15px;
}
.error404-logo {
    width: 380px;
    height: 248px;
    margin-bottom: 20px;
    @media (max-width: 540px){
        width: 168px;
        height: 110px;
        margin-bottom: 10px;
    }
    &_link {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(../img/creature-gif.gif);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      
    }
    @media (max-width: 375px) {
        width: 168px;
        height: 110px;
    }
}
.error404-404 {
    font-weight: 600;
    font-size: 80px;
    line-height: 109px;
    @media (max-width: 486px){
        font-size: 60px;
        line-height: 82px;
    }
}
.error404-page {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: 486px){
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    }
    @media (max-width: 320px){
        margin-bottom: 15px;
    }
}
.error404-text {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 28px;
    @media (max-width: 320px){
        margin-bottom: 15px;
    }
}
.error404-wrapper {
    width: 90%;
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    @media (max-width:725px) {
        margin-bottom: 25px;
        }
}
.error404-search {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid #425369;

}
.search-zone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #161828;
    outline: none;
    border: none;
    font-size: 16px;
    line-height: 22px;
    color: #EEEEEE;
    padding: 10px 25px 10px 50px;
    text-overflow: ellipsis;
    &::placeholder{
        color: #425369;
        font-size: 16px;
        line-height: 22px;
    }
}
.search-btn {
    position: absolute;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url(../img/search.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.error404-nav {
    width: 90%;
    max-width: 700px;
    padding: 0  10px;
}
.error404-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:725px) {
       flex-direction: column;
        }

    &_item{
        padding: 0 10px;
        @media (max-width:725px) {
             padding: 0 0 20px 0;
             }
             @media (max-width: 320px){
                padding: 0 0 10px 0;
             }
    }
    &_item a {
        color: #6d94c4;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
    }
}
